@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.name{
    margin-top: 30px;
    text-align: center;
    font-family: 'Staatliches', cursive;
    font-size: 40px;
    background-color: white;

}

.menu{
    text-align: center;
}

.card{
    padding-top: 30px;
    padding-bottom: 30px;
}


.text{
    padding-top: 5%;
}
